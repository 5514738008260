
import { computed, ref, watch } from 'vue';

// icons
import { close, send, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
        IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonTextarea,
        IonGrid, IonCol, IonRow, IonSpinner, IonLabel, IonButtons, IonButton, IonIcon, IonBackButton,
        onIonViewDidEnter, onIonViewWillLeave, loadingController, } from '@ionic/vue';
import LogoImg from "@/components/LogoImg.vue";
import PageHeader from '@/components/headers/PageHeader.vue';

// compsables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import { useRouter } from 'vue-router';

// service
import ChatService from '@/services/ChatService';

export default {
  name: 'ChatbotPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonFooter,
                IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonTextarea,
                IonGrid, IonCol, IonRow, IonSpinner, IonLabel, IonButtons, IonButton, IonIcon,
                LogoImg, PageHeader, },
  setup() {
    // methods
    const { t, locale, } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { presentAlert, openLoginModal, uniqueId } = utils();

    // user data
    const contentEl = ref(null);
    const waitingBotResp = ref(false);
    const latestBotResp = ref("");
    const userPrompt = ref("");
    const messages = ref([]);
    const loading = ref(true);

    // state variables
    const user = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const settings = computed(() => store.state.settings);

    const scrollToBottom = () => {
      setTimeout(() => {
        contentEl.value.$el.scrollToBottom();
      }, 100)
    }
    const addNewMsgObj = (msgObj) => {
      messages.value.push(msgObj);
      scrollToBottom();
    }

    onIonViewDidEnter(() => {
      ChatService.getUserChatbotPrompts().then(res => {
        loading.value = false;
        for (const row of res) {
          const { id, prompt, botResponse } = row;
          messages.value.push({ id: `p${id}`, fromUser: true, content: prompt });
          if (botResponse) messages.value.push({ id: `b${id}`, content: botResponse });
        }
        scrollToBottom();
      });
    })

    return {
      // icons
      close, send,
      
      // variables
      contentEl,
      locale, loading,
      user, userLoggedIn,
      settings,
      userPrompt, messages,
      waitingBotResp, latestBotResp,

      // methods
      t,
      sendMsg: async () => {
        // Show user prompt on UI
        const sendingPrompt = userPrompt.value;
        userPrompt.value = "";
        const msgId  = `m${uniqueId()}`;
        const newPrompt = { id: msgId, fromUser: true, content: sendingPrompt, };
        addNewMsgObj(newPrompt);

        // Send request to Chatbot
        latestBotResp.value = "..."
        waitingBotResp.value = true;
        const url = "https://rembg.mlol.pet/gpt";
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer 617ba408824b9f2c2e9e1b61e2c04765",
            },
            body: JSON.stringify({
              "version":"1.0","type":"query","query":[{
                "role":"user","content": sendingPrompt.trim(),"timestamp":new Date().valueOf()
              }],"user_id": user.value.id,"conversation_id": "","message_id":"",
            })
          });
          waitingBotResp.value = false;

          if (!response.ok) throw new Error(response.statusText);

          // This data is a ReadableStream
          const data = response.body;
          if (!data) return;
          latestBotResp.value = ""; // empty bot response first

          const reader = data.getReader();
          const decoder = new TextDecoder();
          let done = false;

          while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
            const chunkValue = decoder.decode(value);
            for (const message of chunkValue.split("\n")) {
              const matches = message.match(/\{([^}]+)\}/);
              if (matches) {
                const parsed = JSON.parse(matches[0]);
                if (parsed.text) {
                  latestBotResp.value += parsed.text;
                  scrollToBottom();
                }
              }
            }
          }
          addNewMsgObj({ id: `b-${msgId}`, content: latestBotResp.value }); // bot msg
        } catch (e) {
          presentAlert("Bot did not response. Please try again");
          userPrompt.value = sendingPrompt;
          latestBotResp.value = "";
        } finally {
          ChatService.insertUserChatbotPrompts([{
            ...newPrompt,
            botResponse: latestBotResp.value,
          }]);
          latestBotResp.value = "";
          waitingBotResp.value = false;
        }
      }
    }
  },
}
