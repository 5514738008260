import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  async getUserChatbotPrompts() {
    return (await firebase.app().functions('asia-east2').httpsCallable('chat-getUserChatbotPrompts')({})).data;
  },
  async insertUserChatbotPrompts(prompts) {
    return (await firebase.app().functions('asia-east2').httpsCallable('chat-insertUserChatbotPrompts')({ prompts })).data;
  },
}